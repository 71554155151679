

import {BottomLeftSide} from 'src/containers/BottomLeftSide';
import {BottomRightSide} from 'src/containers/BottomRightSide';
import 'src/containers/Footer.css'

export function Footer() {
	return (
		<footer className="layer-footer">
			<BottomLeftSide></BottomLeftSide>
			<BottomRightSide></BottomRightSide>
		</footer>
	)
}