
import { MainSlider } from 'src/containers/routers/home/MainSlider';
import { Home } from 'src/containers/routers/home/Home';
import { Services } from 'src/containers/routers/home/Services';
import { RequestProform } from 'src/containers/routers/home/RequestProform';
import 'src/containers/routers/home/Content.css';

export function Content(props) {
	return (
		<main className="layer-main">
			<MainSlider></MainSlider>
			<Home layerRef={props.layerRefs.homeRef}></Home>
			<Services layerRef={props.layerRefs.serviceRef}></Services>
			<RequestProform></RequestProform>
			{/*<AdditionalServices></AdditionalServices>*/}
		</main>
	)
}