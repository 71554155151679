
import { TopSide } from 'src/containers/TopSide';
import { Logo } from 'src/containers/Logo';
import { Navigator } from 'src/containers/Navigator';
import 'src/containers/Header.css';

export function Header(props) {
	return (
		<header className="layer-header">
			<TopSide></TopSide>
			<Logo></Logo>
			<Navigator
				layerRefs={props.layerRefs}>
			</Navigator>
		</header>
	)
}