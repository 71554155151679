
import { Component } from 'react';
import { FontIcon } from 'src/components/FontIcon';
import 'src/components/CustomizedLabel.css';

export class CustomizedLabel extends Component {

	constructor(props) {
		super(props);
		this.state = {}
	}
	render() {
		let eleClass = this.props.className;
		if (this.props.selected) eleClass += " selected";
		return (
			<div
				className={eleClass}
				onClick={this.props.onClick}
				>
				<label className={this.props.textClass}>
					{ this.props.icon
						? <FontIcon 
							icon={this.props.icon} 
							className={this.props.textClass}
							/>
						: null}
					{this.props.description}
				</label>
			</div>
		)
	}
}

export class CustomizedLink extends Component {

	constructor(props) {
		super(props);
		this.state = {}
	}
	render() {
		let eleClass = this.props.className;
		if (this.props.selected) eleClass += " selected";
		return (
			<div
				className={eleClass}
				onClick={this.props.onClick}
				>
				<a href={ this.props.href ? this.props.href: '#'}
					target={this.props.target}
					className={this.props.textClass}>
					{ this.props.icon
						? <FontIcon 
							icon={this.props.icon} 
							className={this.props.textClass}
							/>
						: null}
					{this.props.description}
				</a>
			</div>
		)
	}
}