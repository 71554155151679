
import React, { useRef, useEffect } from 'react';
import { TextArea, InputTextWithIcon } from 'src/components/Inputs';
import { Button } from 'src/components/Buttons';
import 'src/containers/routers/home/Content.css';
import 'src/containers/routers/contact/Contact.css';

export function Contact(props) {
	const inputNameRef = useRef(null);
	useEffect(()=> {
		inputNameRef.current.focus();
	});

	const handleActionClick = (e) => {
		e.preventDefault();
		console.log("here contact");
	}
	return (
		<div className="layer-contact">
			<form>
				<h2>Contacto</h2>
				<InputTextWithIcon placeholder="Nombre *" icon="user"
					inputRef={inputNameRef}>
				</InputTextWithIcon>
				<InputTextWithIcon placeholder="Email *" icon="fa-envelope">
				</InputTextWithIcon>
				<TextArea placeholder="Ingrese Mensaje" rows="12">
				</TextArea>
				<Button text="Enviar Mensaje"
					onClick={handleActionClick}></Button>
			</form>
		</div>
	)
}