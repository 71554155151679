
import { useNavigate } from "react-router-dom";
import 'src/containers/Logo.css';
import MyImage from 'src/media/blsolutions-logo-without-bg.png';

export function Logo() {
	let navigate = useNavigate();
	const handleImageOnClick = (e) => {
		navigate("/")
	}
	return (
		<div className="header-logo">
			<figure>
				<img src={MyImage} alt="BL Solutions Perú"
					onClick={handleImageOnClick} />
			</figure>
		</div>
	)
}