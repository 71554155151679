
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { navigatorList } from 'src/constants';
import { FontIcon } from 'src/components/FontIcon';
import { withRouter, sortBy } from 'src/helpers';
import 'src/containers/Navigator.css';

function NavigatorSandwich(props) {
	return (<div className="navigator-sandwich">
		<NavLink to="#" onClick={props.onClick}>
			<FontIcon 
				icon="fa-bars"
				className="navigator-sandwich"
				/>
		</NavLink>
	</div>)
}
function NavigatorItems (props) {
	const options = props.options;
	return (<div className={!props.show ?'navigator-items':'navigator-items display'}>
		{ options.length ? options.map((item, index) => {
				return (
					<NavLink key={item.key}
						to={item.path}
						onClick={item.onClick}
						className={ ({isActive}) => {
							return (!isActive ? item.className: `${item.className} active`)
						}}
					>
						{item.description}
					</NavLink>
				);
			})
			: null}
	</div>)
}
class NavigatorComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			options: [...navigatorList],
			navItems: false,
		}
	};
	isHomePage = () => {
		const pathname = window.location.pathname;
		if (pathname.match(/^\/(home|service)/) || pathname === "/"){
			return true;
		}
		return false;
	}
	handleHomeClick = (e) => {
		e.preventDefault();
		const item = this.getOption('Home');
		if (!this.isHomePage()) {
			this.props.router.navigate(item.path);
		} else {
			const offset = this.props.layerRefs.homeRef.current.offsetTop;
			window.scrollTo({ behavior: 'smooth', top: offset, });
		}
	};
	handleServiceClick = (e) => {
		e.preventDefault();
		const item = this.getOption('Servicio');
		if (!this.isHomePage()) {
			this.props.router.navigate(item.path);
		} else {
			const offset = this.props.layerRefs.serviceRef.current.offsetTop;
			window.scrollTo({ behavior: 'smooth', top: offset, });
		}
	};
	handleSandwichClick = (e) => {
		e.preventDefault();
		this.setState((state, props)=> {
			return {navItems: !state.navItems}
		})
	}
	getOption = (key) => {
		const item = this.state.options.filter((x) => {
			return x.description === key
		});
		return item? {...item[0]} : null;
	}
	getHandlers = () => {
		return [
			{key: "Home", event: this.handleHomeClick,},
			{key: "Servicio", event: this.handleServiceClick,},
			{key: "Tracking", event: null,},
			{key: "Contacto", event: null,},
		]
	} 

	render() {
		const events = this.getHandlers();
		const options = sortBy(this.state.options, 'key');
		events.forEach((ele, index) => {
			let item = options.filter(x => x.description === ele.key);
			if (item) {
				item[0].onClick = ele.event;
			}
		});

		return (
			<nav className="header-navigator-v2">
				<NavigatorItems options={options}
					show={this.state.navItems}>
				</NavigatorItems>
				<NavigatorSandwich
					onClick={this.handleSandwichClick}>
				</NavigatorSandwich>
			</nav>
		)
	}
}

export const Navigator = withRouter(NavigatorComponent)