
import 'src/components/Card.css';

export function Card(props) {
	return (
		<div className="card">
			<figure>
				<img src={props.image} alt=""></img>
			</figure>
			<h2>{props.title}</h2>
			<p>{props.paragraph}</p>
		</div>
	)
}