import {Card} from 'src/components/Card';
import {serviceList} from 'src/constants';
import 'src/containers/routers/home/Services.css';

export function Services(props) {
	return (
		<div 
			ref={props.layerRef}
			className="main-service"
			>
			<div className="service-content">
				<h1>Servicios Especiales</h1>
				<p>
					Magna id sunt deserunt consectetur amet do in tempor labore exercitation sed velit aliqua excepteur in ut in esse quis qui eiusmod labore sit veniam anim sunt magna cillum tempor irure est ea nostrud aliquip aliquip esse ut.
				</p>
			</div>
			<div className="service-cards">
				{!serviceList ? null : serviceList.map((service, index) => {
					return <Card key={index} {...service}></Card>
				})}
			</div>
		</div>
	);
}