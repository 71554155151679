
import React from 'react';
import Slider from 'react-slick';
import { sliderImages } from 'src/constants';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'src/containers/routers/home/MainSlider.css'

export function MainSlider() {
	var settings = {
		dots: true,
		infinite: true,
		speed: 800,
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		cssEase: 'linear',
		autoplay: true,
		autoplaySpeed: 2500,
		touchMove: false,
		arrows: true,
	};

	return (
		<Slider {...settings}>
			{ !sliderImages 
				? null
				: sliderImages.map((image, index) => {
					return (
						<div key={index} className="main-slide">
							<figure>
								<img src={image.src} alt=""></img>
							</figure>
						</div>
					)
				})
			}
		</Slider>
	);
}