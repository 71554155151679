
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fas);
library.add(fab);

export function FontIcon(props) {
	return (
		<FontAwesomeIcon 
			icon={props.icon}
			className={props.className}
		/>
	)
}