

import HomeImage from 'src/media/images/home-image.jpeg';
import 'src/containers/routers/home/Home.css';

export function Home(props) {
	return (
		<div
			ref={props.layerRef}
			className="main-home"
			>
			<div className="home-content">
				<h1>BL Solutions Perú</h1>
				<p>
					Dolore qui ut excepteur dolore et aliqua est irure id dolore aliqua cillum esse reprehenderit in ex veniam ullamco deserunt ut minim voluptate dolor officia qui sint ex cillum esse duis fugiat ut ullamco voluptate cupidatat amet deserunt sed commodo quis ex fugiat incididunt eiusmod qui id magna voluptate ex mollit aliqua magna sunt nulla amet ut ea non enim culpa ut esse eu dolor velit eiusmod in in quis adipisicing consectetur nisi in ut dolore ullamco fugiat adipisicing eu fugiat adipisicing velit excepteur sed non mollit tempor in dolor voluptate laborum mollit dolor ullamco occaecat incididunt labore magna reprehenderit amet esse quis consequat in enim excepteur irure enim in ut sed consectetur velit consequat id velit reprehenderit qui id veniam officia voluptate adipisicing quis enim non sed dolor amet ex aliqua dolore amet nisi eu minim ullamco deserunt aute consequat dolore in aliqua in aliquip eu excepteur eu in in incididunt consequat dolore excepteur proident do pariatur dolore dolor nisi cupidatat in ex cupidatat anim occaecat sint fugiat minim in sed consequat dolore culpa cillum exercitation fugiat anim mollit ullamco ut consectetur veniam sed magna nostrud commodo ut magna in officia.
				</p>
			</div>
			<div className="home-image">
				<figure>
					<img src={HomeImage} alt=""></img>
				</figure>
			</div>
		</div>
	);
}
