
import React, { useRef } from 'react';
import { Routes, Route, Link } from "react-router-dom";
import {
  Header,
  Content,
  Footer,
  BodyBackgroundSliders,
  Tracking,
  Contact,
} from 'src/containers';
import 'src/App.css';

function App() {
  const homeRef = useRef();
  const serviceRef = useRef();
  const layerRefs = { homeRef, serviceRef, };
  return (
    <div className="body-layer">
      <BodyBackgroundSliders></BodyBackgroundSliders>
      <Header layerRefs={layerRefs}></Header>
      <Routes>
        <Route path="/"
              element={<Content layerRefs={layerRefs} />} />
        <Route path="/home"
              element={<Content layerRefs={layerRefs} />} />
        <Route path="/service"
              element={<Content layerRefs={layerRefs} />} />
        <Route path="/tracking"
              element={<Tracking />} />
        <Route path="/contact"
          element={<Contact />} />
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
