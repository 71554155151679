
import React, { useRef, useEffect } from 'react';
import { InputTextWithIcon } from 'src/components/Inputs';
import { Button } from 'src/components/Buttons';
import 'src/containers/routers/home/Content.css';
import 'src/containers/routers/tracking/Tracking.css';

export function Tracking(props) {
	const inputRef = useRef();
	useEffect(()=> {
		inputRef.current.focus();
	});
	
	const handleActionClick = (e) => {
		e.preventDefault();
		console.log("here tracking");
	}
	return (
		<div className="layer-tracking">
			<form>
				<h2>Tracking</h2>
				<InputTextWithIcon
					placeholder="Nro. Guia / Referencia *"
					icon="fa-magnifying-glass"
					inputRef = {inputRef}>
				</InputTextWithIcon>
				<Button text="Buscar"
					onClick={handleActionClick}></Button>
			</form>
		</div>
	)
}