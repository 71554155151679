

export const navigatorList = [
	{
		key: 1,
		description: "Home",
		className: "navigator-item",
		onClick: null,
		path: '/home',
	},
	{
		key: 2,
		description: "Servicio",
		className: "navigator-item",
		onClick: null,
		path: '/service',
	},
	{
		key: 3,
		description: "Tracking",
		className: "navigator-item",
		onClick: null,
		selected: false,
		path: '/tracking',
	},
	{
		key: 4,
		description: "Contacto",
		className: "navigator-item",
		onClick: null,
		path: '/contact',
	},
];