
import { CustomizedLabel } from 'src/components/CustomizedLabel';
import 'src/containers/BottomLeftSide.css';

const allRightLabel = "BL Solutions Perú SAC © All Rights Reserved";

export function BottomLeftSide (){
	return (
		<div className="bottom-left">
			<CustomizedLabel
				description={allRightLabel}
				className="label-footer-leftside"
				textClass="text-white">
			</CustomizedLabel>
		</div>
	)
}