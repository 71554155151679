

import ServicePicking from 'src/media/images/service-packing.jpeg';
import ServiceTransportation from 'src/media/images/service-transportation.jpeg';
import ServiceCounseling from 'src/media/images/service-counseling.jpeg';

const defaultText = "Incididunt ut reprehenderit consectetur cillum ut nulla non quis irure laborum dolor deserunt dolore proident dolor ea cupidatat laborum fugiat eu cupidatat."
export const serviceList = [
	{image: ServicePicking,
	title: 'Empaque y Almacenamiento', paragraph: defaultText, },
	{image: ServiceTransportation,
	title: 'Transporte de Carga', paragraph: defaultText, },
	{image: ServiceCounseling,
	title: 'Asesoria en Importación y Exportación', paragraph: defaultText, },
]