
import {
	bodyBackgroundImages as Images,
} from 'src/constants/bodyBackgroundImages';
import 'src/containers/BodyBackgroundSliders.css';

export function BodyBackgroundSliders(props){
	return (
		<div className="layer-background">
			{!Images
				?null
				:Images.map((image)=>{
					return (
						<div key={image.id}>
							<figure>
								<img alt={image.text} src={image.image}></img>
							</figure>
						</div>
					)
				})}
		</div>
	)
}