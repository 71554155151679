
import { CustomizedLabel } from 'src/components/CustomizedLabel';
import 'src/containers/BottomRightSide.css';

const items = [
	{
		icon: 'fa-location-dot',
		description: 'Centro Aéreo | Av. Elmer Faucett Cdra. 30 S/N Sector B Módulo E, Of. 209, Callao - Perú',
		className: 'label-footer-rightside',
		textClass: 'text-white',
	},
	{
		icon: 'phone',
		description: '+511 574-5084',
		className: 'label-footer-rightside',
		textClass: 'text-white',
	},
	{
		icon: 'envelope',
		description: 'operaciones@blsolutionsperu.com',
		className: 'label-footer-rightside',
		textClass: 'text-white',
	},
];
export function BottomRightSide (){
	return (
		<div className="bottom-right">
			{items.length
				? items.map((item, index) => {
					return <CustomizedLabel key={index} {...item}></CustomizedLabel>
				})
				: null}
		</div>
	)
}