

import { CustomizedLink } from 'src/components/CustomizedLabel';
import 'src/containers/TopSide.css';

const items = [
	{
		icon: 'phone',
		description: '+511 574-5084',
		className: 'label-inline',
		textClass: 'text-white',
		href: 'tel:+5115745084',
	},
	{
		icon: 'envelope',
		description: 'operaciones@blsolutionsperu.com',
		className: 'label-inline',
		textClass: 'text-white',
		href: 'mailto:operaciones@blsolutionsperu.com',
	},
	{
		icon: 'fa-brands fa-instagram',
		description: '',
		className: 'label-inline',
		textClass: 'text-white',
		href: 'https://www.instagram.com/blsolutionsperu/',
		target: '_blank',
	},
	{
		icon: 'fa-brands fa-facebook',
		description: '',
		className: 'label-inline',
		textClass: 'text-white',
		href: 'https://www.facebook.com/BLSOLUTIONSPERU',
		target: '_blank',
	},
	{
		icon: 'fa-location-dot',
		description: 'Lun - Sab. 09 am - 6 pm',
		className: 'label-inline',
		textClass: 'text-white',
		href: '',
	},
];

export function TopSide(props) {
	return (
		<div className="top-side">
			{ items.length ? items.map((item, index) => {
				return (
					<CustomizedLink key={index} {...item}></CustomizedLink>
				)
			}): null}
		</div>
	)
}