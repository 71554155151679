
import ImageContainerShip from 'src/media/images/container-ships.jpeg';
import ImageContainerShip2 from 'src/media/images/container-ships2.jpeg';

export const bodyBackgroundImages = [
	{
		id: 1,
		image: ImageContainerShip,
		text: "Container Ship 1",
	},
	{ 
		id: 2,
		image: ImageContainerShip2,
		text: "Container Ship 2",
	},
]