
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons';
import 'src/components/Inputs.css';

library.add(fas);

export function InputTextWithIcon(props) {
	return (
		<span className="control-text-icon">
			<FontAwesomeIcon  icon={props.icon || 'pen'} />
			<input type="text"
				placeholder={props.placeholder}
				disabled={props.disabled || false}
				readOnly={props.readOnly || false}
				ref={props.inputRef || null}
				className={props.className}
				onChange={props.onChange}
				>
			</input>
		</span>
	)
}

export function InputText(props) {
	return (
		<input type="text" 
			className="control-text"
			{...props} ></input>
	)
}

export function InputEmail(props) {
	return (
		<input type="email"
			className="control-text"
			{...props} ></input>
	)
}

export function TextArea(props) {
	return (
		<textarea  className="control-textarea"
			{...props} ></textarea>
	)
}