
import 'src/components/Buttons.css';

export function Button(props) {
	return (
		<span className={props.className||'control-button'}>
			{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
			<a href="#" onClick={props.onClick} >
				{props.text}
			</a>
		</span>
	)
}