
import AirFreightSlider from 'src/media/images/airfreight-slider.jpeg';
import OceanFreightSlider from 'src/media/images/ocean-freight-slider.jpeg';
import GroundFreightSlider from 'src/media/images/ground-freight-slider.jpeg';

export const sliderImages = [
	{ name: 'Air Freight', src: AirFreightSlider, },
	{ name: 'Ocean Freight', src: OceanFreightSlider, },
	{ name: 'Ground Freight', src: GroundFreightSlider, },
];
