import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

export const withRouter = (Component) => {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
		  <Component
		    {...props}
		    router={{ location, navigate, params }}
		  />
		);
	}

  	return ComponentWithRouterProp;
}

export const sortBy = (source, field, direction = 1) => {
	const ascDirection = (a, b) => {
			return a > b ? 1 : -1
	}
	const descDirection = (a, b) => {
			return a > b ? -1 : 1
	}
	return source.sort((a, b) => {
			const value1 = a[field];
			const value2 = b[field];
			let directionMethod = null;
			if (direction === 1) {
				directionMethod = ascDirection;
			} else {
				directionMethod = descDirection;
			}
			return directionMethod(value1, value2)
	})
}