
import { useNavigate } from 'react-router-dom';
import 'src/containers/routers/home/RequestProform.css';

export function RequestProform(props){
	const navigate = useNavigate();

	const handleActionClick = (e) => {
		e.preventDefault();
		navigate('/contact');
	}
	return (
		<div className="main-proform">
			<h2>Solicite una Cotización</h2>
			<p>Llene nuestro formato y gustosos le responderemos a la brevedad.</p>
			<span>
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
				<a href="#" onClick={handleActionClick} >
					Contáctenos
				</a>
			</span>
		</div>
	)
}
